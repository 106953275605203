<template>
  <div>
    <vl-map
      :load-tiles-while-animating="true"
      :load-tiles-while-interacting="true"
      data-projection="EPSG:4326"
      style="padding: 50px; height: 750px"
    >
      <vl-view
        :zoom.sync="zoom"
        :center.sync="center"
        :rotation.sync="rotation"
      ></vl-view>

      <vl-geoloc @update:position="geolocPosition = $event">
        <template slot-scope="geoloc">
          <vl-feature v-if="geoloc.position" id="position-feature">
            <vl-geom-point :coordinates="geoloc.position"></vl-geom-point>
            <vl-style-box>
              <vl-style-icon
                src="_media/marker.png"
                :scale="0.4"
                :anchor="[0.5, 1]"
              ></vl-style-icon>
            </vl-style-box>
          </vl-feature>
        </template>
      </vl-geoloc>

      <vl-layer-tile id="osm">
        <vl-source-osm></vl-source-osm>
      </vl-layer-tile>
    </vl-map>
    <div style="padding: 20px">
      Zoom: {{ zoom }}<br />
      Center: {{ center }}<br />
      <!--  Rotation: {{ rotation }}<br />
      My geolocation: {{ geolocPosition }} -->
    </div>
  </div>
</template>
  
  <script>
export default {
  created() {
    this.lon = this.$route.params["lon"];
    this.lat = this.$route.params["lat"];
    this.center = [this.lon, this.lat];
  },
  data() {
    return {
      zoom: 15,
      lon: 0,
      lat: 0,
      center: [0, 0],
      rotation: 0,
      geolocPosition: undefined,
    };
  },
};
</script>